<template lang="pug">
  div#templateColsContainer.px-1.pt-1
    .content-loader-center.m-0.h-100(v-if='isLoadingIntervention || isCreatingIntervention || isUpdatingIntervention || loadingData')
      .text-center.flex-center
        .loading-bg-inner 
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | {{ isLoadingIntervention || loadingData ? 'Chargement du formulaire d\'édition d\'une intervention' : 'Enregistrement de l\'intervention...' }}
    div.details-container.w-100(v-else)
      div.d-flex.content-scrollable-sticky.justify-content-center.w-100( style="height: calc(100% - 70px)")
          b-col.w-100(md="12" xl="9")
            validation-observer(ref='formEditIntervention' #default='{ errors }')
              b-form.h-100(autocomplete='off' style="overflow-x: hidden;")
                .d-flex.w-100.mb-1.align-items-center.justify-content-between
                  .d-flex.content-header
                    h2.content-header-title.float-left.m-0.pr-1.mr-1(style="border-right: 1px solid #e2e2e2")
                      | {{ $route.meta.pageTitle }}
                    .content-header-search.mr-1
                      h3.m-0(style="text-transform: capitalize;")
                        | {{ getTitle() }}
                  .content-header-actions.d-flex
                    feather-icon.cursor(icon='XIcon' size='24' @click='cancel()')
                b-row.my-0
                  .d-none.d-lg-block.mb-1(style="padding: 0 1rem !important;")
                    b-form-group(label-for="dataFormDateRanger" label="Selectionner une ou plusieurs dates") 
                    .e-small(style="margin-left: -4px;")
                      ejs-chiplist#chip-default.justify-content-between(v-on:click.native="id == 0 ? setDates($event) : ''" cssClass="justify-content-between" v-if="!isMobile" :enabled="dataForm.status !== 2")
                        e-chips
                          e-chip(text='Aujourd\'hui' cssclass='e-primary' :enabled="id == 0")
                          e-chip(text='Ce mois-ci' cssclass='e-primary' :enabled="id == 0")
                          e-chip(text='Cette semaine' cssclass='e-primary' :enabled="id == 0")
                    ejs-calendar(id='calendar' ref="calendarInstance" locale='fr' :showTodayButton="false" :enabled="id == 0 || dataForm.status !== 2" :firstDayOfWeek="1" :isMultiSelection="true" :values="dataForm.dates" @change="setDate" v-if="!isMobile")
                  .d-none.d-lg-block(style="padding: 0 1rem !important; flex:1")
                    b-row.my-0
                      b-col.m-0.p-0(cols="12" style="padding-bottom:0px !important")
                        label Date(s) d'intervention
                      b-col.py-0.mb-0(cols="12")
                        .d-flex.align-items-center(v-if="id > 0" style="flex-wrap: wrap;")
                          .e-chips.bg-secondary.text-white.d-flex(v-for="(date, indexDate) of dataFormDatesOrderer" :key="indexDate" style="padding: 2px 4px; margin-bottom: 4px; line-height:16px; border-radius: 4px; margin-right:5px; min-width: fit-content;")
                            span.mr-1 {{ formatDateLocale(date) }}
                        .d-flex.align-items-center(v-else style="flex-wrap: wrap;")
                          .e-chips.bg-primary.text-white.d-flex(v-for="(date, indexDate) of dataFormDatesOrderer" :key="indexDate" style="padding: 2px 4px; margin-bottom: 4px; line-height:16px; border-radius: 4px; margin-right:5px; min-width: fit-content;")
                            span.mr-1 {{ formatDateLocale(date) }}
                            span.material-icons-outlined.m-0.p-0.cursor-pointer(@click="deleteDate(date)" style="font-size: 14px;") close
      
                        label.e-error(for="Collaborateurs" id="Collaborateurs-info" style="display: block;" v-if="dataForm.dates.length==0")
                          | Sélectionnez au moins une date
                    b-row.my-0
                        b-col.py-0.mb-1.mt-1
                            b-form-group(label-for="dataFormCollaboratorsList"  label="Collaborateur(s) *")
                              validation-provider(name="dataFormCollaboratorsList" #default='{ errors }')
                                ejs-multiselect(id='dataFormCollaboratorsList' name="Collaborateurs" :dataSource='collaboratorsList' :closePopupOnSelect="false" :fields="{text:'displayLabel',value:'id'}" placeholder='Rechercher un collaborateur' v-model="dataForm.collaboratorIds" sortOrder="Ascending" filterType='Contains' required="" :enabled="dataForm.status !== 2")
                    b-row.my-0
                        b-col.py-0.mb-1
                            b-form-group(label-for="dataFormCompaniesList"  label="Client *")
                              validation-provider(name="dataFormCompaniesList" #default='{ errors }')
                                ejs-dropdownlist(id='dataFormCompaniesList' name="Company" :showClearButton="true" :dataSource='companyFilter' :fields="{text:'name',value:'id'}" placeholder='Rechercher un client' v-model="dataForm.companyId" sortOrder="Ascending" filterType='Contains' required="" :allowFiltering='true' :enabled="dataForm.status !== 2" :footerTemplate="'footerTemplate'")
                                  template(v-slot:footerTemplate)
                                    .p-50 
                                      ejs-button(
                                        v-on:click.native="newCompany"
                                        :content="'+ Nouveau client'"
                                        :isPrimary="true"
                                        locale="fr"
                                        :spinSettings="{ position: 'Right' }"
                                        :iconCss="'e-btn-sb-icons e-icons e-open-link'"
                                        cssClass="w-100"
                                        iconPosition="Right"
                                      )
                    b-row.my-0
                        b-col.py-0.mb-1
                            b-form-group(label-for="dataFormAffairsList"  label="Affaire *")
                              validation-provider(name="dataFormAffairsList" #default='{ errors }')
                                ejs-dropdownlist(id='dataFormAffairsList' name="Affair" :showClearButton="true" :dataSource='affairFilter' :fields="{ text: 'displayLabel', value: 'id' }" placeholder='Rechercher une affaire' v-model="dataForm.affairId" sortOrder="Ascending" filterType='Contains' required="" :allowFiltering='true' :enabled="dataForm.status !== 2" :footerTemplate="'footerTemplate'")
                                  template(v-slot:footerTemplate)
                                    .p-50 
                                      ejs-button(
                                        v-on:click.native="newAffair"
                                        :content="'+ Nouvelle affaire'"
                                        :isPrimary="true"
                                        locale="fr"
                                        :spinSettings="{ position: 'Right' }"
                                        :iconCss="'e-btn-sb-icons e-icons e-open-link'"
                                        cssClass="w-100"
                                        iconPosition="Right"
                                      )
                    b-row.my-0
                        b-col.py-0.mb-1
                            b-form-group(label-for="dataFormDuration"  label="Durée (h) *")
                              validation-provider(name="dataFormDuration" #default='{ errors }')
                                ejs-numerictextbox(type="tel" id="dataFormDuration" name="Duration" v-model="dataForm.duration" :min='0' :max='24' :step='1' required="" :enabled="dataForm.status !== 2")
                    b-row.my-0
                        b-col.py-0.mb-1
                            b-form-group(label-for="dataFormMessage"  label="Commentaire")
                              validation-provider(name="dataFormMessage" #default='{ errors }')
                                ejs-textbox(id="dataFormMessage" v-model="dataForm.message" :multiline="true" :enabled="dataForm.status !== 2")
                  .d-block.d-lg-none.col 
                    b-row.my-0 
                      b-col 
                        ejs-datepicker(id="datepicker" :openOnFocus='true' ref="datepickerInstance" locale="fr" :enabled="id == 0 || dataForm.status !== 2"  :showTodayButton="true" :firstDayOfWeek="1" :isMultiSelection="false" v-model="dataForm.dates[0]" @change="isMobile ? selectDate : setDate" :placeholder="'Choisissez une date'")
                    b-row.my-0
                      b-col
                        b-form-group(label-for="dataFormCollaboratorsList"  label="Collaborateur(s) *")
                          validation-provider(name="dataFormCollaboratorsList" #default='{ errors }')
                            ejs-multiselect(id='dataFormCollaboratorsList' name="Collaborateurs" :dataSource='collaboratorsList' :closePopupOnSelect="false" :fields="{text:'displayLabel',value:'id'}" placeholder='Rechercher un collaborateur' v-model="dataForm.collaboratorIds" sortOrder="Ascending" filterType='Contains' required="" :enabled="dataForm.status !== 2")
                    b-row.my-0
                      b-col
                        b-form-group(label-for="dataFormCompaniesList"  label="Client *")
                          validation-provider(name="dataFormCompaniesList" #default='{ errors }')
                            ejs-dropdownlist(id='dataFormCompaniesList' name="Company" :dataSource='companyFilter' :fields="{text:'name',value:'id'}" placeholder='Rechercher un client' v-model="dataForm.companyId" sortOrder="Ascending" filterType='Contains' required="" :allowFiltering='true' :enabled="dataForm.status !== 2")
                    b-row.my-0
                      b-col 
                        b-form-group(label-for="dataFormAffairsList"  label="Affaire *")
                          validation-provider(name="dataFormAffairsList" #default='{ errors }')
                            ejs-dropdownlist(id='dataFormAffairsList' name="Affair" :dataSource='affairFilter' :fields="{ text: 'displayLabel', value: 'id' }" placeholder='Rechercher une affaire' v-model="dataForm.affairId" sortOrder="Ascending" filterType='Contains' required="" :allowFiltering='true' :enabled="dataForm.status !== 2")
                    b-row.my-0
                      b-col 
                        b-form-group(label-for="dataFormDuration"  label="Durée (h) *")
                          validation-provider(name="dataFormDuration" #default='{ errors }')
                            ejs-numerictextbox(type="tel" id="dataFormDuration" name="Duration" v-model="dataForm.duration" :min='0' :max='24' :step='1' required="" :enabled="dataForm.status !== 2")
                    b-row.my-0 
                      b-col 
                        b-form-group(label-for="dataFormMessage"  label="Commentaire")
                          validation-provider(name="dataFormMessage" #default='{ errors }')
                            ejs-textbox(id="dataFormMessage" v-model="dataForm.message" :multiline="true" :enabled="dataForm.status !== 2")                        
                vs-divider.mb-1.mt-1(color='#cccccc')
                b-row.my-0
                  b-col(cols="12").py-0.mb-50.w-100.d-flex.justify-content-between.align-items-center
                      div.d-flex.align-items-center
                          h4.mb-0 Détails
                  b-col(cols='12' style='padding-bottom:0px !important')
                    b-form-group(label-for="interventionCustomFieldTemplateId"  label="Formulaire lié à l'intervention *")
                      ejs-dropdownlist(id='interventionCustomFieldTemplateId' placeholder='Selectionnez un formulaire' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource='customFieldTemplatesList' :fields="{ text: 'label', value: 'id' }" v-model='dataForm.customFieldTemplateId'  @change="setCustomFieldTemplate" ref='customFieldTemplateDropdown' :enabled="dataForm.status !== 2")
                        template(v-slot:footerTemplate)
                          div.p-50
                            ejs-button(v-on:click.native="newCustomFieldTemplate()" :content="'+ Nouveau formulaire de champs personnalisés'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
                            vs-divider.mb-50.mt-1(color='#cccccc')
                div(v-if="!dataCustomFieldTemplateLoading")
                  vs-divider.mt-3
                  div(v-for="(group, index) of dataCustomFieldTemplate.customFieldGroups" :key="group.id")
                    b-row.m-0
                      b-col(cols='12' style='padding-bottom:0px !important; padding-left:0px !important; padding-right:0px !important')
                        h4.m-0(:style="'color:'+group.color+' !important'") {{ group.label }}
                    b-row.m-0
                      b-col(cols='12' style='padding-bottom:0px !important; padding-left:0px !important; padding-right:0px !important' v-for="(customField, indexCustomField) of group.customFields" :key="indexCustomField")
                        b-row.m-0.d-flex.align-items-center(v-if="customField.typeField==11")
                          b-form-checkbox.custom-control-primary( :id="'interventionCustomField_'+customField.id+'_'+group.id" v-model="formInterventionCustomFields[customField.id]")
                          label.mb-0(:for="'interventionCustomField_'+customField.id+'_'+group.id") {{ customField.label }}
                        b-row.m-0.d-flex.flex-end.justify-content-between.align-items-end.w-100(v-else :label-for="'interventionCustomField_'+customField.id+'_'+group.id" v-model="formInterventionCustomFields[customField.id]")
                          label(:for="'interventionCustomField_'+customField.id+'_'+group.id") {{ customField.label }}
                          div.mb-50(:key="keySignatureLocked")
                            b-button(v-if="customField.typeField === 12 && !isSignatureLocked(customField.id, group.id)" @click="lockSignature(customField.id, group.id)" variant='outline-primary' :disabled="dataForm.status === 2")
                                feather-icon(icon='CheckIcon')
                            b-button(v-else-if="customField.typeField === 12" @click="unlockSignature(customField.id, group.id)" variant='outline-secondary' :disabled="dataForm.status === 2")
                                feather-icon(icon='LockIcon')
                            b-button.ml-1(v-if="customField.typeField === 12 && !isSignatureLocked(customField.id, group.id)" @click="clearSignature(group.id, customField.id)" variant='outline-danger' :disabled="dataForm.status === 2")
                                span Effacer
                        ejs-numerictextbox(type="tel" v-if="customField.typeField==1 || customField.typeField==2 || customField.typeField==3" :id="'interventionCustomField_'+customField.id+'_'+group.id" :label="customField.label" :value='0' :format="customField.typeField==2 ? '########.## €' : (customField.typeField==3 ? `#######0.#####' %'` : null)" v-model="formInterventionCustomFields[customField.id]" :enabled="dataForm.status !== 2")
                        ejs-textbox(v-else-if="customField.typeField==4 || customField.typeField==5" :multiline="customField.typeField==5" :id="'interventionCustomField_'+customField.id+'_'+group.id" :label="customField.label" v-model="formInterventionCustomFields[customField.id]" :enabled="dataForm.status !== 2")
                        ejs-richtexteditor(v-else-if="customField.typeField==6" ref="rteObj" v-model="formInterventionCustomFields[customField.id]" :label="customField.label" :toolbarSettings="toolbarSettings" :enabled="dataForm.status !== 2")
                        ejs-datepicker(v-else-if="customField.typeField==7" :id="'interventionCustomField_'+customField.id+'_'+group.id" :format="'dd/MM/yyyy'" locale='fr' placeholder="JJ/MM/AAAA" :label="customField.label" v-model="formInterventionCustomFields[customField.id]" :enabled="dataForm.status !== 2")
                        ejs-datetimepicker(v-else-if="customField.typeField==8" :id="'interventionCustomField_'+customField.id+'_'+group.id" locale='fr'  :firstDayOfWeek="1" placeholder="JJ/MM/AAAA HH:MM" :label="customField.label" v-model="formInterventionCustomFields[customField.id]" :enabled="dataForm.status !== 2")
                        ejs-dropdownlist(v-else-if="customField.typeField==9" :id="'interventionCustomField_'+customField.id+'_'+group.id" placeholder='Selectionnez une valeur' popupHeight='300px' filterType='Contains' :allowFiltering='true' :dataSource='customField.options ? customField.options.split(";") : []' :label="customField.label" v-model="formInterventionCustomFields[customField.id]" :enabled="dataForm.status !== 2")
                        ejs-multiselect(v-else-if="customField.typeField==10" :id="'interventionCustomField_'+customField.id+'_'+group.id" placeholder='Selectionnez une valeur' popupHeight='300px' filterType='Contains' :allowFiltering='true' :dataSource='customField.options ? customField.options.split(";") : []' :label="customField.label" v-model="formInterventionCustomFields[customField.id]" :enabled="dataForm.status !== 2")
                        ejs-signature(v-if="customField.typeField==12" :id="'interventionCustomFieldSignature_'+customField.id+'_'+group.id" :label="customField.label" @created="loadSignaturesIntoComponents(customField.id, group.id)"
                        :name="'signatureComponent_'+customField.id+'_'+group.id"
                        v-model="formInterventionCustomFields[customField.id]"
                        :ref="'signatureComponent_'+customField.id+'_'+group.id"
                        class="ejs-signature-input"
                        :disabled="dataForm.status === 2"
                        )
                    vs-divider.mb-50.mt-2(color='#cccccc')(v-if="index < dataCustomFieldTemplate.customFieldGroups.length - 1")
              vs-divider.mb-50.mt-2(color='#cccccc') 
              b-row.my-0
                b-col.py-0(cols='12')
                  .d-flex.justify-content-between
                    div
                      b-button(variant='outline-primary' @click='cancel()')
                        feather-icon(icon='ArrowLeftIcon')
                        | Annuler
                    b-button(variant='primary' @click='confirm' :disabled="verifCustom()" v-if="dataForm.status !== 2")
                        feather-icon(icon='SaveIcon' v-if='dataForm.id')
                        feather-icon(icon='PlusIcon' v-else)
                        | {{ dataForm.id ? 'Modifier' : 'Ajouter' }}       
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isMobile as checkIfMobile } from "@/types/api-orisis/library/GlobalOperations.ts";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { getComponent } from "@syncfusion/ej2-base";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import Vue from "vue";
import {
  RichTextEditorPlugin,
  Toolbar,
  Link,
  Image as ImageTextEditor,
  Count,
  HtmlEditor,
  QuickToolbar,
  Table,
} from "@syncfusion/ej2-vue-richtexteditor";

Vue.use(RichTextEditorPlugin);

import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  provide: {
    richtexteditor: [
      Toolbar,
      Link,
      ImageTextEditor,
      Count,
      HtmlEditor,
      QuickToolbar,
      Table,
    ],
  },
  props: {
    id: {
      default: 0,
    },
    mode: {
      type: String,
      default: "single",
    },
  },
  data() {
    return {
      keySignatureLocked: 0,
      toolbarSettings: {
        items: [
          "Bold",
          "Italic",
          "Underline",
          "StrikeThrough",
          "FontName",
          "FontSize",
          "FontColor",
          "BackgroundColor",
          "LowerCase",
          "UpperCase",
          "SuperScript",
          "SubScript",
          "EmojiPicker",
          "|",
          "Formats",
          "Alignments",
          "NumberFormatList",
          "BulletFormatList",
          "Outdent",
          "Indent",
          "|",
          "CreateTable",
          "CreateLink",
          "|",
          "ClearFormat",
          "Print",
          "SourceCode",
          "FullScreen",
          "|",
          "Undo",
          "Redo",
        ],
      },
      // Gestion du form principal
      loadingData: false,
      dataOrigine: {},
      name: "editIntervention",
      dateRangeDefault: null,
      dataForm: {
        id: 0,
        dates: [new Date(dayjs().format("YYYY-MM-DD")).toISOString()],
        collaboratorIds: [],
        affairId: null,
        companyId: null,
        message: null,
        duration: 0,
        customFieldTemplateId: null,
        status: 0,
        customFields: [],
      },
      base64SignatureImage: null,
      signatures: [],
      isMobile: checkIfMobile(),
      formInterventionCustomFields: {},
      dataCustomFieldTemplate: {},
      dataCustomFieldTemplateLoading: true,
      firstDayOfWeek: 1,
    };
  },
  computed: {
    ...mapGetters([
      "isLoadingIntervention",
      "isCreatingIntervention",
      "isUpdatingIntervention",
      "institutionSelected",
      "userCollaboratorId",
      "collaboratorsList",
      "userCollaborator",
      "companiesList",
      "affairsList",
      "customFieldTemplatesList",
    ]),
    affairFilter() {
      if (!this.dataForm.companyId || this.loadingData) return this.affairsList;
      return this.affairsList.filter(
        (el) => el.company?.id == this.dataForm.companyId
      );
    },
    companyFilter() {
      return this.companiesList.filter(
        (company) => company.companyTypeId === 5
      );
    },
    dataFormDatesOrderer() {
      let dates = structuredClone(this.dataForm.dates).sort((a, b) => a - b);
      return dates;
    },
  },
  async created() {},
  async mounted() {
    this.loadingData = true;
    const allPromises = [
      this.getInterventionEvent(this.id),
      this.getCollaborators({}),
      this.getCompanies({ companyType: 5 }),
      this.getAffairs({}),
      this.getCustomFieldTemplates({
        type: 1,
      }),
    ];
    Promise.all(allPromises).then(async (res) => {
      if (this.dataForm.customFieldTemplateId) {
        await this.setCustomFieldTemplate({
          itemData: this.customFieldTemplatesList.find(
            (el) => el.id == this.dataForm.customFieldTemplateId
          ),
        });
      }
      this.loadingData = false;
    });

    if (this.$route.meta.mode == "single" && this.userCollaboratorId) {
      this.dataForm.collaboratorIds = [parseInt(this.userCollaboratorId)];
    }
    window.addEventListener("resize", this.updateIsMobile);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIsMobile);
  },
  activated() {
    if (!this.loadingData) {
      this.getCustomFieldTemplates({
        type: 1,
      });
      if (this.dataForm.customFieldTemplateId) {
        this.setCustomFieldTemplate({
          itemData: this.customFieldTemplatesList.find(
            (el) => el.id == this.dataForm.customFieldTemplateId
          ),
        });
      }
    }
  },
  methods: {
    newCompany() {
      if (!this.dataForm.id) {
        this.$router.push({
          name: "new-company",
          params: {
            companyTypeId: 5,
            name: "clients",
            routeOrigine: `intervention/new-intervention`,
          },
        });
      } else {
        this.$router.push({
          name: "new-company",
          params: {
            companyTypeId: 5,
            name: "clients",
            routeOrigine: `intervention/edit-intervention`,
          },
        });
      }
    },
    newAffair() {
      if (!this.dataForm.id) {
        this.$router.push({
          name: "new-affair",
          params: { routeOrigine: "intervention/new-intervention/" },
        });
      } else {
        this.$router.push({
          name: "new-affair",
          params: { routeOrigine: "intervention/edit-intervention/" },
        });
      }
    },
    getImageDimensions(file) {
      return new Promise((resolve, reject) => {
        var i = new Image();
        i.onload = () => {
          resolve({ w: i.width, h: i.height });
        };
        i.src = file;
      });
    },
    ...mapActions([
      "getCollaborators",
      "getAffairs",
      "getCompanies",
      "createIntervention",
      "updateIntervention",
      "getInterventionById",
      "getCustomFieldTemplates",
    ]),
    formatCurrency,
    lockSignature(customFieldId, groupId) {
      const signatureComponent = document.getElementById(
        "interventionCustomFieldSignature_" + customFieldId + "_" + groupId
      );
      if (signatureComponent) {
        getComponent(signatureComponent, "signature").isReadOnly = true;
      }
      this.keySignatureLocked++;
    },
    unlockSignature(customFieldId, groupId) {
      const signatureComponent = document.getElementById(
        "interventionCustomFieldSignature_" + customFieldId + "_" + groupId
      );
      if (signatureComponent) {
        getComponent(signatureComponent, "signature").isReadOnly = false;
      }
      this.keySignatureLocked++;
    },
    isSignatureLocked(customFieldId, groupId) {
      const signatureComponent = document.getElementById(
        "interventionCustomFieldSignature_" + customFieldId + "_" + groupId
      );
      if (signatureComponent) {
        return getComponent(signatureComponent, "signature").isReadOnly;
      } else {
        return false;
      }
    },
    updateIsMobile() {
      this.isMobile = checkIfMobile();
    },
    formatDateLocale(str) {
      return (
        dayjs(str).format("dddd D MMMM YYYY").charAt(0).toUpperCase() +
        dayjs(str).format("dddd D MMMM YYYY").slice(1)
      );
    },
    getTitle() {
      return this.dataFormDatesOrderer
        ? this.dataFormDatesOrderer.length == 1
          ? dayjs(this.dataFormDatesOrderer[0]).format("dddd D MMMM YYYY")
          : dayjs(this.dataFormDatesOrderer[0]).format("dddd D MMMM YYYY") +
            " au " +
            dayjs(
              this.dataFormDatesOrderer[this.dataFormDatesOrderer.length - 1]
            ).format("dddd D MMMM YYYY")
        : "";
    },
    getInterventionEvent(id) {
      return new Promise((resolve) => {
        if (id > 0) {
          this.getInterventionById({
            interventionId: id,
          }).then((res) => {
            this.dataForm = {
              ...res,
              dates: [res.date],
              affairId: res.affairId,
            };
            this.dataOrigine = JSON.stringify(this.dataForm);
            resolve();
          });
        } else {
          this.initializeForm();
          resolve();
        }
      });
    },
    verifCustom() {
      return (
        this.dataForm.collaboratorIds.length <= 0 ||
        this.dataForm.dates.length <= 0 ||
        !this.dataForm.companyId ||
        !this.dataForm.affairId ||
        this.dataForm.duration <= 0
      );
    },
    async initializeForm() {
      this.dataForm = {
        id: 0,
        dates: [new Date(dayjs().format("YYYY-MM-DD"))],
        collaboratorIds:
          this.$route.meta.mode == "single" && this.userCollaboratorId
            ? [parseInt(this.userCollaboratorId)]
            : [],
        duration: 0,
        customFieldTemplateId: null,
        status: 0,
        institutionId: this.institutionSelected.id,
        companyId: null,
        affairId: null,
        message: null,
        customFields: [],
      };
      this.dataOrigine = JSON.stringify(this.dataForm);

      if (this.$route.params.date) {
        this.dataForm.dates = [new Date(this.$route.params.date).toISOString()];
      }
    },
    selectDate(args) {
      this.dataForm.dates = [args.value];
    },
    setDate(args) {
      if (args.value && args.isInteracted) {
        this.dataForm.dates = args.values;
      } else if (args.value == null) {
        this.dataForm.dates = [dayjs().toISOString()];
      }
    },
    setDates(args) {
      this.dataForm.dates = [];
      let startDate = new Date(dayjs().format("YYYY-MM-DD"));
      let endDate = new Date(dayjs().format("YYYY-MM-DD"));
      if (args.target.textContent == "Cette semaine") {
        startDate = new Date(dayjs().startOf("week").format("YYYY-MM-DD"));
        endDate = new Date(dayjs().endOf("week").format("YYYY-MM-DD"));
      } else if (args.target.textContent == "Ce mois-ci") {
        startDate = new Date(dayjs().startOf("month").format("YYYY-MM-DD"));
        endDate = new Date(dayjs().endOf("month").format("YYYY-MM-DD"));
      }
      const oneDay = 24 * 60 * 60 * 1000; // Nombre de millisecondes dans une journée

      const dates = [];
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
          dates.push(new Date(currentDate));
        }
        currentDate.setTime(currentDate.getTime() + oneDay);
      }
      if (dates && dates.lenth == 0) {
        dates.push(new Date(currentDate));
      }
      this.dataForm.dates = dates;
    },
    deleteDate(date) {
      let findIndex = structuredClone(this.dataForm.dates).findIndex(
        (el) =>
          dayjs(el).format("YYYY-MM-DD") == dayjs(date).format("YYYY-MM-DD")
      );
      this.dataForm.dates.splice(findIndex, 1);
      this.$refs.calendarInstance.removeDate(date);
    },
    async confirm(e) {
      const signatureElements = document.querySelectorAll('[id*="Signature"]');
      for (let element of signatureElements) {
        const signatureComponent = getComponent(
          document.getElementById(element.id),
          "signature"
        );
        const base64Signature = signatureComponent.getSignature();
        let id = element.id.split("_")[1];
        const existingSignatureIndex = this.signatures.findIndex(
          (sig) => sig.id === id
        );
        if (existingSignatureIndex != -1) {
          this.signatures[existingSignatureIndex].base64Data = base64Signature;
          // signatureComponent.load(signatureElements);
        } else {
          this.signatures.push({ id: id, base64Data: base64Signature });
        }
        // if (signatureComponent.load) {
        //   signatureComponent.load(base64Signature);
        // }
        this.formInterventionCustomFields[id] = base64Signature;
      }
      this.$refs.formEditIntervention.validate().then(async (success) => {
        if (success) {
          if (this.dataForm.id) {
            await this.updateIntervention({
              intervention: this.dataForm,
              interventionCustomFields: this.formInterventionCustomFields,
            });
          } else {
            await this.createIntervention({
              intervention: this.dataForm,
              interventionCustomFields: this.formInterventionCustomFields,
            });
          }
          if (
            this.$route.meta.mode == "single" &&
            this.dataForm.collaboratorIds.length <= 1
          ) {
            this.$tabs.close({ to: "/intervention/my-interventions" });
          } else {
            this.$tabs.close({
              to: "/intervention/interventions-by-collaborators",
            });
          }
        }
      });
    },
    loadSignaturesIntoComponents(customFieldId, groupId) {
      const signatureComponent = document.getElementById(
        "interventionCustomFieldSignature_" + customFieldId + "_" + groupId
      );
      const signatureData = this.dataForm.customFields.find(
        (cf) => cf.customField.id === customFieldId
      );
      if (signatureComponent && signatureData && signatureData.value) {
        this.lockSignature(customFieldId, groupId);

        this.getImageDimensions(signatureData.value).then((res) => {
          getComponent(signatureComponent, "signature").load(
            signatureData.value,
            res.w,
            res.h
          );
        });
      }
    },
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.dataForm)) {
        if (this.$route.params.routeOrigine) {
          this.$tabs.close({ to: this.$route.params.routeOrigine });
        } else {
          if (
            this.$route.meta.mode == "single" &&
            this.dataForm.collaboratorIds.length <= 1
          ) {
            this.$tabs.close({ to: "/intervention/my-interventions" });
          } else {
            this.$tabs.close({
              to: "/intervention/interventions-by-collaborators",
            });
          }
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
    clearSignature(groupId, customFieldId) {
      const signatureComponent = document.getElementById(
        "interventionCustomFieldSignature_" + customFieldId + "_" + groupId
      );
      if (signatureComponent) {
        getComponent(signatureComponent, "signature").clear();
        this.formInterventionCustomFields[customFieldId] = "";
      }
    },
    setCustomFieldTemplate(args) {
      this.formInterventionCustomFields = {};
      this.dataCustomFieldTemplateLoading = true;
      this.dataCustomFieldTemplate = args.itemData;
      this.dataCustomFieldTemplate.customFieldGroups.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      );
      this.dataCustomFieldTemplate.customFieldGroups.forEach((group) => {
        group.customFields.forEach((field) => {
          group.customFields.sort((a, b) => a.position - b.position);
          if (field.typeField == 12) {
            const signatureData = this.dataForm.customFields.find(
              (cf) => cf.customField.id == field.id
            );
            if (signatureData) {
              this.signatures.push({
                id: field.id,
                refName: "signatureComponent" + field.id,
                base64Data: signatureData.value,
              });
              this.formInterventionCustomFields[field.id] = signatureData.value;
            }
          } else {
          }
        });
      });

      for (
        let indexGroup = 0;
        indexGroup < this.dataCustomFieldTemplate.customFieldGroups.length;
        indexGroup++
      ) {
        const group =
          this.dataCustomFieldTemplate.customFieldGroups[indexGroup];
        for (
          let indexField = 0;
          indexField < group.customFields.length;
          indexField++
        ) {
          const field = group.customFields[indexField];
          if (
            this.dataForm.customFields
              .map((el) => {
                return { id: el.customField.id, value: el.value };
              })
              .find((el) => el.id == field.id)
          ) {
            this.formInterventionCustomFields[field.id] =
              this.dataForm.customFields
                .map((el) => {
                  return { id: el.customField.id, value: el.value };
                })
                .find((el) => el.id == field.id).value;
          } else {
            this.formInterventionCustomFields[field.id] = field.defaultValue;
          }
        }
      }
      this.dataCustomFieldTemplateLoading = false;
    },
    newCustomFieldTemplate() {
      if (this.$refs.customFieldTemplateDropdown) {
        this.$refs.customFieldTemplateDropdown.hidePopup();
      }
      this.$router.push({ path: "/parameters/new-custom-field-template" });
    },
  },
};
</script>

<style lang="scss">
@import "~@syncfusion/ej2-vue-richtexteditor/styles/bootstrap5.css";
.e-chip-list .e-chip:last-child {
  margin-right: 0px !important;
}
</style>
